
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class extends Vue {
  formSubmitted = false;
  dates = [];
  valid = false;
  firstName = "";
  lastName = "";
  info = "";
  email = "";
  progressValue = 0;
  showProgress = false;
  firstNameRules = [
    (v: string) => !!v || "Name is required",
    (v: string) =>
      (v && v.length <= 10) || "Name must be less than 10 characters",
  ];
  lastNameRules = [
    (v: string) => !!v || "Surname is required",
    (v: string) =>
      (v && v.length <= 10) || "Surname must be less than 10 characters",
  ];
  infoRules = [
    (v: string) =>
      v.length <= 350 || "Please keep me your message below 350 characters",
  ];
  emailRules = [
    (v: string) => !!v || "E-mail is required",
    (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  ];

  @Prop({ default: null, type: Array })
  blockedDates: any;

  allowedDates(val: any): any {
    return this.blockedDates.indexOf(val) === -1;
  }

  submit() {
    const valid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();

    if (valid) {
      this.showProgress = true;
      const progressInterval = setInterval(() => {
        this.progressValue += 10;
      }, 500);
      const headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("firstname", this.firstName);
      urlencoded.append("lastname", this.lastName);
      urlencoded.append("email", this.email);
      urlencoded.append("message", this.info);
      urlencoded.append("type", "request");
      urlencoded.append(
        "dates",
        `Arrival: ${this.dates[0]} Departure: ${this.dates[0]}`
      );

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: urlencoded,
      };

      fetch(
        "https://us-central1-villa-jay.cloudfunctions.net/sendMailOverHttp",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => {
          console.error("error", error);
          // changing variable state in catch rather than above because of weird cors error that needs looking at
          this.formSubmitted = true;
          this.showProgress = false;
          clearInterval(progressInterval);
        });
    }
  }
}
