
import firebase from "firebase";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class AddReviewComponent extends Vue {
  @Prop({ default: false, type: Boolean }) adminView: boolean;

  valid = false;
  rating = 0;
  name = "";
  review = "";
  db = firebase.firestore();

  nameRules = [
    (v: string) =>
      (v && v.length <= 10) || "Name must be less than 10 characters",
  ];
  reviewRules = [
    (v: string) =>
      (v && v.length <= 300) || "Your review must be less than 300 characters",
  ];

  updateRating(value: number): void {
    this.rating = value;
  }

  saveReview(): void {
    const uniqid = Date.now();
    this.db
      .collection("reviews")
      .add({
        id: uniqid,
        name: this.name,
        review: this.review,
        rating: this.rating,
      })
      .then((docRef) => {
        console.log("Document successfully written!", docRef.id);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  sendReviewViaEmail(): void {
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("name", this.name);
    urlencoded.append("review", this.review);
    urlencoded.append("rating", this.rating.toString());
    urlencoded.append("type", "review");
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: urlencoded,
      // redirect: "follow",
    };
    fetch(
      "https://us-central1-villa-jay.cloudfunctions.net/sendMailOverHttp",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => console.log(result));
  }

  submit() {
    if (this.adminView) {
      this.saveReview();
    } else {
      this.sendReviewViaEmail();
    }
    const valid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();

    console.log(valid);
  }
}
