
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  computed: {
    ...mapGetters(["weatherInfo", "mainWeather"]),
  },
})
export default class extends Vue {
  get sunset() {
    return this.$store.getters.sunset;
  }
  get weatherIcon(): string {
    const timeNowInTR = dayjs().tz("Turkey").format("HH:mm:ss");
    let iconType;
    this.sunset < timeNowInTR ? (iconType = "n") : (iconType = "d");

    return `https://openweathermap.org/img/wn/${this.$store.state.weatherData.icon}${iconType}@2x.png`;
  }
}
