
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import firebase from "firebase/app";
import "firebase/firestore";

dayjs.extend(utc);

import { Vue, Component, Watch } from "vue-property-decorator";
import FormComponent from "@/components/form-component.vue";
import ReviewStasComponent from "@/components/reviews/review-stats.vue";

@Component({
  components: {
    FormComponent,
    ReviewStasComponent,
  },
})
export default class extends Vue {
  value = "";
  dayjs = dayjs;
  type = "month";
  events: any = [];
  dates: any[] = [];
  mode = "stack";
  menu = false;
  modal = false;
  weekday = [1, 2, 3, 4, 5, 6, 0];
  isBooked = false;
  dateSelected = false;
  blockedDates: string[] = [];

  get localeValue() {
    // todo need to actually get user locale
    return this.value
      ? dayjs(this.value).format("DD-MM-YYYY")
      : dayjs().format("DD-MM-YYYY");
  }

  @Watch("value") valueChanged() {
    this.dateBooked();
  }

  getEvents() {
    const events = [];

    for (let i = 0; i < this.dates.length; i++) {
      events.push({
        // name: this.dates[i].id,
        start: this.dates[i].dateRange[0],
        end: this.dates[i].dateRange[1],
        color: "#2c3e50",
      });
    }

    this.events = events;
  }

  // todo: maybe add this to a helper file
  dateRange(startDate: any, endDate: any, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  }

  dateBooked(): void {
    this.dateSelected = true;
    const dates: any = [];
    this.events.map((event: any) => {
      dates.push(this.dateRange(event.start, event.end));
      this.blockedDates = dates
        .flat()
        .map((date: string) => dayjs(date).format("YYYY-MM-DD"));

      this.$store.commit("SET_BLOCKED_DATES", this.blockedDates);

      const datesToCheck = dates
        .flat()
        .filter(
          (el: any) =>
            dayjs(this.value).format("YYYY-MM-DD") ===
            dayjs(el).format("YYYY-MM-DD")
        );

      datesToCheck.length ? (this.isBooked = true) : (this.isBooked = false);
    });
    // this.$nextTick(() => {
    //   document
    //     .querySelector(".c-availability__result")
    //     ?.scrollIntoView({ block: "end", behavior: "smooth" });
    // });
  }

  getData(): any {
    let data;
    firebase
      .firestore()
      .collection("dates")
      .get()
      .then((querySnapshot) => {
        this.dates = querySnapshot.docs.map((doc) => doc.data());
      })
      .finally(() => this.getEvents());
    return data;
  }

  created(): void {
    this.getData();
  }
}
