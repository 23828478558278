import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const getTime = (unix_timestamp: number) => {
  if (unix_timestamp > 0) {
    const date = new Date(unix_timestamp * 1000);
    const hours =
      date.getHours().toString().length <= 1
        ? "0" + date.getHours()
        : date.getHours();
    const minutes =
      date.getMinutes().toString().length <= 1
        ? "0" + date.getMinutes()
        : date.getMinutes();
    const seconds =
      date.getSeconds().toString().length <= 1
        ? "0" + date.getSeconds()
        : date.getSeconds();
    return hours + ":" + minutes + ":" + seconds;
  }
};

export default new Vuex.Store({
  state: {
    loggedIn: false,
    unit: "c",
    tempUnit: "c",
    language: "en",
    blockedDates: [],
    weatherData: {
      location: "",
      temp: null,
      feelsLike: null,
      description: null,
      icon: null,
      info: null,
      wind: null,
      clouds: null,
      humidity: null,
      timezone: 0,
      sunset: 0,
      sunrise: 0,
      country: "",
      visibility: 0,
    },
  },
  getters: {
    mainWeather(state) {
      const { temp, feelsLike, description, icon, info, location } =
        state.weatherData;
      return {
        location,
        temp,
        feelsLike,
        description,
        info,
        icon,
      };
    },
    weatherInfo(state) {
      const { wind, clouds, humidity, visibility } = state.weatherData;
      return {
        wind,
        clouds,
        humidity,
        visibility,
      };
    },
    sunset(state) {
      return getTime(state.weatherData.sunset + state.weatherData.timezone);
    },
    sunrise(state) {
      return getTime(state.weatherData.sunrise + state.weatherData.timezone);
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value;
    },
    SET_WEATHER_DATA(state, data) {
      state.weatherData = data;
    },
    SET_BLOCKED_DATES(state, data) {
      state.blockedDates = data;
    },
  },
  actions: {
    async getWeatherData({ commit, state }, search: string) {
      let unit;

      try {
        state.tempUnit === "c" ? (unit = "metric") : "imperial";
        if (state.tempUnit === "c") {
          unit = "metric";
        } else {
          unit = "imperial";
        }
        const response = await axios.get(
          `${process.env.VUE_APP_APIBASE}/weather?q=${search}&units=${unit}&lang=${state.language}&appid=${process.env.VUE_APP_APIKEY}`
        );
        const weatherData = {
          location: search,
          name: response.data.name,
          temp: response.data.main.temp,
          tempMin: response.data.main.temp_min,
          tempMax: response.data.main.temp_max,
          feelsLike: response.data.main.feels_like,
          description: response.data.weather[0].description,
          // only returning the first two characters
          icon: response.data.weather[0].icon.substring(0, 2),
          info: response.data.weather[0].main,
          wind: response.data.wind.speed,
          humidity: response.data.main.humidity,
          clouds: response.data.clouds.all,
          country: response.data.sys.country,
          sunset: response.data.sys.sunset,
          sunrise: response.data.sys.sunrise,
          timezone: response.data.timezone,
          visibility: response.data.visibility,
        };
        commit("SET_WEATHER_DATA", weatherData);
      } catch (error) {
        console.error(error);
        commit("SET_WEATHER_DATA", {});
      }
    },
  },
  modules: {},
});
