
import { Vue, Component } from "vue-property-decorator";
import WeatherComponent from "@/components/weather-component.vue";
import firebase from "firebase";

@Component({
  components: {
    WeatherComponent,
  },
})
export default class NavComponent extends Vue {
  open = false;

  checkAvailability(): void {
    if (this.$route.path !== "/") {
      this.$router.push("/");
    }
    if (window.innerWidth < 860) {
      this.open ? (this.open = false) : (this.open = true);
    }

    document
      .getElementById("availability")
      ?.scrollIntoView({ block: "end", behavior: "smooth" });
  }

  menuOpen(): void {
    this.open = !this.open;
    this.$emit("menu-open", this.open);
  }

  getDimensions(): void {
    const width = document.documentElement.clientWidth;
    if (width > 860) {
      this.open = true;
    }
    if (width < 860 && this.open) {
      this.open = false;
    }
  }

  logOut(): void {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.$router.push("/");
        this.$store.commit("SET_LOGGED_IN", false);
      });
  }

  mounted(): void {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
  }
}
