
import { Vue, Component } from "vue-property-decorator";
import firebase from "firebase";
import NavComponent from "@/components/nav-component.vue";

@Component({
  components: {
    NavComponent,
  },
})
export default class App extends Vue {
  menuOpen = false;

  openMenu(value: boolean): void {
    this.menuOpen = value;
  }
  mounted() {
    this.$store.dispatch("getWeatherData", "bodrum");
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.commit("SET_LOGGED_IN", true);
      }
    });
  }
}
