
import { Vue, Component } from "vue-property-decorator";
import AvailabilityComponent from "@/components/availability-component.vue";
import AddReviewComponent from "@/components/reviews/add-review.vue";

@Component({
  components: {
    AvailabilityComponent,
    AddReviewComponent,
  },
  metaInfo: {
    title: "Villa Jay - Turkbuku, Bodrum Peninsula",
    meta: [
      {
        name: "description",
        vmid: "description",
        content:
          "Villa Jay is a luxury villa in Türkbükü Bodrum. A recent refurbishment ensures this house is one of the most desired properties in the area",
      },
      {
        property: "og:description",
        vmid: "og:description",
        content:
          "Villa Jay is a luxury villa in Türkbükü Bodrum. A recent refurbishment ensures this house is one of the most desired properties in the area",
      },
      {
        property: "og:image",
        content:
          "https://basethree.s3.eu-west-1.amazonaws.com/5131939934109696",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: "https://villajay.com/",
      },
      {
        name: "keywords",
        content:
          "luxury villa, bodrum, turkey, south turkey, turkbuku, summer break, winter break, holiday, luxury house rental, luxury holiday",
      },
    ],
  },
})
export default class Home extends Vue {
  tab = "About";
  windowSize = 550;
  review = false;
  showAllImages = false;
  images = [
    {
      src: "/img/images/image.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay swimming pool from above and mountain view",
    },
    {
      src: "/img/images/image-2.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay swimming pool, sun terrace and beautiful garden",
    },
    {
      src: "/img/images/image-3.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay main entrance and garden",
    },
    {
      src: "/img/images/image-4.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay indoor sunken garden",
    },
    {
      src: "/img/images/image-5.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay kitchen",
    },
    {
      src: "/img/images/image-6.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay by candle light",
    },
    {
      src: "/img/images/image-8.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay top floor landing",
    },
    {
      src: "/img/images/image-9.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay dinning table from front door",
    },
    {
      src: "/img/images/image-10.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay large sitting room",
    },
    {
      src: "/img/images/image-11.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay large sitting room",
    },
    {
      src: "/img/images/image-12.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay indoor dining area",
    },
    {
      src: "/img/images/image-13.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay downstairs sitting room",
    },
    {
      src: "/img/images/image-14.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay downstairs sitting room",
    },
    {
      src: "/img/images/image-15.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay bedroom with en-suite bathroom",
    },
    {
      src: "/img/images/image-16.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay bedroom with en-suite bathroom",
    },
    {
      src: "/img/images/image-17.jpg",
      width: 324,
      height: 324,
      alt: "Villa Jay bedroom with en-suite bathroom",
    },
  ];

  get vertical(): boolean {
    return this.windowSize >= 550 ? false : true;
  }

  get imagesToShow(): { src: string; width: number; height: number }[] {
    return this.showAllImages ? this.images : this.images.slice(0, 6);
  }

  get mapOptions(): { options: unknown; styles: unknown } {
    return {
      options: {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: false,
      },
      ...this.mapStyle,
    };
  }

  get mapStyle(): { styles: unknown } {
    return {
      styles: [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#e9e9e9",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#dedede",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#333333",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#f2f2f2",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ],
    };
  }

  mounted(): void {
    window.onresize = () => {
      this.windowSize = window.innerWidth;
    };
  }
}
