
import { Vue, Component } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/firestore";

@Component({})
export default class ReviewStatsComponent extends Vue {
  reviews: any[] = [];
  showReviews = false;
  /**
   * Props
   **/
  /**
   * Data
   **/
  /**
   * Computed Properties
   **/
  get ratingAverage(): number {
    let rating = 0;
    if (this.reviews) {
      for (const review of this.reviews) {
        rating += review?.rating;
      }
    }
    return Math.round((rating / this.totalNumberOfReviews) * 10) / 10;
  }

  get totalNumberOfReviews(): number {
    return this.reviews ? this.reviews.length : 0;
  }
  // store state and getters
  // other computed properties
  /**
   * Methods
   **/
  // store mutations and actions
  // other methods
  // lifecycle methods
  getReviews(): void {
    let data;
    firebase
      .firestore()
      .collection("reviews")
      .get()
      .then((querySnapshot) => {
        this.reviews = querySnapshot.docs.map((doc) => doc.data());
      });
    return data;
  }

  get reviewMetrics(): Array<{
    id: number;
    ratingPerc: number;
    color: string;
    total: number;
  }> {
    const fiveStar: Array<{ rating: number }> = [];
    const fourStar: Array<{ rating: number }> = [];
    const threeStar: Array<{ rating: number }> = [];
    const twoStar: Array<{ rating: number }> = [];
    const oneStar: Array<{ rating: number }> = [];
    const star: Array<{ rating: number }> = [];
    if (this.reviews) {
      this.reviews.forEach((element) => {
        if (element.rating === 5) {
          fiveStar.push({
            rating: element.rating,
          });
        }
        if (element.rating === 4) {
          fourStar.push({
            rating: element.rating,
          });
        }
        if (element.rating === 3) {
          threeStar.push({
            rating: element.rating,
          });
        }
        if (element.rating === 2) {
          twoStar.push({
            rating: element.rating,
          });
        }
        if (element.rating === 1) {
          oneStar.push({
            rating: element.rating,
          });
        }
        if (element.rating === 0) {
          star.push({
            rating: element.rating,
          });
        }
      });
    }

    return [
      {
        id: 5,
        ratingPerc: Math.round(
          (fiveStar.length / this.totalNumberOfReviews) * 100
        ),
        color: `blue`,
        total: fiveStar.length,
      },
      {
        id: 4,
        ratingPerc: Math.round(
          (fourStar.length / this.totalNumberOfReviews) * 100
        ),
        color: `blue lighten-1`,
        total: fourStar.length,
      },
      {
        id: 3,
        ratingPerc: Math.round(
          (threeStar.length / this.totalNumberOfReviews) * 100
        ),
        color: `blue lighten-2`,
        total: threeStar.length,
      },
      {
        id: 2,
        ratingPerc: Math.round(
          (twoStar.length / this.totalNumberOfReviews) * 100
        ),
        color: `blue lighten-3`,
        total: twoStar.length,
      },
      {
        id: 1,
        ratingPerc: Math.round(
          (oneStar.length / this.totalNumberOfReviews) * 100
        ),
        color: "blue lighten-4",
        total: oneStar.length,
      },
    ];
  }

  created(): void {
    this.getReviews();
  }
}
