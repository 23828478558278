import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as firebase from "firebase";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyDOGQuFsJyq5AKnQfmVLh9sCAHF-BuJ78I",
  authDomain: "villa-jay.firebaseapp.com",
  projectId: "villa-jay",
  storageBucket: "villa-jay.appspot.com",
  databaseURL: "https://villa-jay.firebaseio.com",
  messagingSenderId: "1023466372683",
  appId: "1:1023466372683:web:cf1b6a08d45eff7c6a97ce",
  measurementId: "G-T2G6XFLXSG",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();

export { db };

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDg0qe-o5o6Nz4i3sMeLhyjXxisJd1CBiI",
    libraries: "places, geometry",
    installComponents: true,
  },
});

Vue.use(VueMeta, { keyName: "metaInfo" });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
